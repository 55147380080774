<template>
  <div style="height:100%">
    <base-loading v-if="loading"></base-loading>
    <v-fade-transition mode="out-in" v-else>
      <router-view />
    </v-fade-transition>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import BaseLoading from '../components/base/loading.vue';

export default {
  name: 'ProductionPlanningVista',
  components: {
    BaseLoading,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('webApp', ['config', 'storageLocation', 'appSchema']),
  },
  async created() {
    this.loading = true;
    // await this.fetchShifts();
    const appId = localStorage.getItem('appId');
    await Promise.all([
      this.fetchShifts(),
      await this.getAppSchema(appId),
    ]);
    if (this.appSchema && this.appSchema.modules && this.appSchema.assetId) {
      this.setAssetId(this.appSchema.assetId);
      this.setModules(this.appSchema.modules);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('productionPlanningVista', ['fetchShifts']),
    ...mapMutations('webApp', ['setConfig', 'resetConfig']),
    ...mapMutations('fileManager', [
      'setModules',
      'setAssetId',
    ]),
    ...mapActions('webApp', ['getAppSchema']),
  },
};
</script>
